import UTMLatLng from "utm-latlng";
import { CoordinateFormat } from "../../enums";
import proj4 from "proj4"
import {
	DecimalDegreeCoordinate,
	DegreesMinutesSecondsCoordinate,
	DegreesMinutesSecondsPoint,
	GabonTraverseMercatorCoordinate,
	IsDecimalDegreeCoordinate,
	UniversalTraverseMercatorCoordinate,
} from "../../models/Coordinates";

export const ConvertCoordinates = (
	coordinates: any[],
	inputFormat: CoordinateFormat,
	outputFormat: CoordinateFormat
): { coordinates: any[]; err: string | null } => {
	var convertedCoordinates: any[] = [];

	console.log(
		"ConvertCoordinates got coordinates for conversion: " + coordinates
	);

	if (
		inputFormat === CoordinateFormat.UniversalTransverseMercator &&
		outputFormat === CoordinateFormat.DecimalDegrees
	) {
		for (var i = 0; i < coordinates.length; i++) {
			console.log("Converting UTM to DD");
			var { coordinate, err } = utmToDecimalDegrees(coordinates[i]);

			if (err != null) {
				return {
					coordinates: [],
					err: err,
				};
			}

			convertedCoordinates.push(coordinate);
		}
	} else if (
		inputFormat === CoordinateFormat.DegreesMinutesSeconds &&
		outputFormat === CoordinateFormat.DecimalDegrees
	) {
		for (i = 0; i < coordinates.length; i++) {
			console.log("Converting DMS to DD");
			var { coordinate, err } = dmsToDecimalDegrees(coordinates[i]);

			if (err != null) {
				return {
					coordinates: [],
					err: err,
				};
			}

			convertedCoordinates.push(coordinate);
		}
	} else if (inputFormat === CoordinateFormat.GabonTransverseMercator && outputFormat === CoordinateFormat.DecimalDegrees) {
		for (i = 0; i < coordinates.length; i++) {
			console.log("Converting GTM to DD");
			var { coordinate, err } = gtmToDecimalDegrees(coordinates[i]);

			if (err != null) {
				return {
					coordinates: [],
					err: err,
				};
			}

			convertedCoordinates.push(coordinate);
		}
	}

	console.log("Result of conversion: " + convertedCoordinates);

	return { coordinates: convertedCoordinates, err: null };
};

const utmToDecimalDegrees = (
	coordinate: UniversalTraverseMercatorCoordinate
): { coordinate: DecimalDegreeCoordinate | null; err: string | null } => {
	const utmConverter = new UTMLatLng();

	const ddCoordinate: any = utmConverter.convertUtmToLatLng(
		coordinate.easting,
		coordinate.northing,
		coordinate.zoneNumber,
		coordinate.zoneLetter
	);

	//console.log(ddCoordinate);

	if (typeof ddCoordinate === "string") {
		return { coordinate: null, err: ddCoordinate };
	}

	return {
		coordinate: {
			longitude: ddCoordinate.lng,
			latitude: ddCoordinate.lat,
		},
		err: null,
	};
};

const dmsToDecimalDegrees = (
	coordinate: DegreesMinutesSecondsCoordinate
): { coordinate: DecimalDegreeCoordinate | null; err: string | null } => {
	var latitude: number = 0;
	var longitude: number = 0;

	var { point, err } = dmsPointToDecimalDegreesPoint(coordinate.latitude);

	if (err !== null) {
		return { coordinate: null, err: err };
	} else if (point) {
		latitude = point;
	}

	var { point, err } = dmsPointToDecimalDegreesPoint(coordinate.longitude);

	if (err !== null) {
		return { coordinate: null, err: err };
	} else if (point) {
		longitude = point;
	}

	return {
		coordinate: {
			latitude: latitude,
			longitude: longitude,
		},
		err: null,
	};
};

const dmsPointToDecimalDegreesPoint = (
	point: DegreesMinutesSecondsPoint
): { point: number | null; err: string | null } => {
	var sign: number = 1;

	if (point.hemisphere === "S" || point.hemisphere === "W") {
		sign = -1;
	}

	var decimalDegreesPoint: number =
		sign * (point.degrees + point.minutes / 60 + point.seconds / 3600);

	return {
		point: decimalDegreesPoint,
		err: null,
	};
};

const gtmToDecimalDegrees = (coordinate: GabonTraverseMercatorCoordinate): { coordinate: DecimalDegreeCoordinate | null; err: string | null } => {

	console.log("Converting GTM: " + coordinate.easting + " " + coordinate.northing + " to DD");

	// Define the GTM projection string
	// Replace the parameters with the correct ones for Gabon Transverse Mercator
	let gtmProjection = '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=500000';
	//let gtmProjection2011 = '+proj=tmerc +lat_0=0 +lon_0=11.5 +k=0.9996 +x_0=1500000 +y_0=5500000'
	//let getProjection3 = '+proj=utm +zone=32 +a=6378249.2 +b=6356515 +towgs84=-74,-130,42,0,0,0,0 +units=m +no_defs'

	let wgs84Projection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

	proj4.defs('GTM', gtmProjection);
    proj4.defs('WGS84', wgs84Projection);

	let wgs84Coordinates = proj4('GTM', 'WGS84', [coordinate.northing, coordinate.easting]);

	if (wgs84Coordinates[0] && wgs84Coordinates[1]) {
		return { coordinate: {longitude: wgs84Coordinates[0], latitude: wgs84Coordinates[1]}, err: null}
	} else {
		console.log("Error converting GTM to DD: " + wgs84Coordinates)
		return { coordinate: null, err: "Could not convert coordinates: " + coordinate.northing + " " + coordinate.easting }
	}

}
